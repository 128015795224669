import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './ProjectsGallery.css';
import Cafelandia from './../../images/projetos/cafelandia.jpeg';
import Pongai from './../../images/projetos/pongai.jpeg';
import Oeiras from './../../images/projetos/oeiras.jpeg';
import MataRoma from './../../images/projetos/mataroma.jpeg';
import Cumbica from './../../images/projetos/cumbica.jpeg';
import BuritisGrande from './../../images/projetos/buritigrande.jpg';
import Balsas from './../../images/projetos/balsas.jpeg';
import SantoAntonio from './../../images/projetos/santoantonio.jpeg';


function ProjectsGallery(props)
{
    const projects = [
        {
            'nome' : 'Cafelândia',
            'id' : 'cafelandia',
            'imagem' : Cafelandia,
            'posicao' : {'top' : 0, 'left' : 0},
            'local' : 'Cafelândia - SP',
            'potencia-instalada' : '2,00 MW',
            'potencia-pico' : '2,59 MWp',
            'energia-gerada' : '5.819 MWh/',
            'terra' : '30',
            'tracker' : 'Monoeixo',
            'placa' : 'Bifacial',
            'concesionaria' : 'CPFL Paulista',
            'status-operacao' : 'Em operação',
            'nome-terra' : {
                "portuguese" : "Direito de Superfície",
                "english" : "Land Rented for"
            }
        },
        {
            'nome' : 'Pongaí',
            'id' : 'pongai',
            'imagem' : Pongai,
            'posicao' : {'top' : 0, 'left' : 0},
            'local' : 'Pongaí - SP',
            'potencia-instalada' : '2,00 MW',
            'potencia-pico' : '2,59 MWp',
            'energia-gerada' : '5.819 MWh/',
            'terra' : '30',
            'tracker' : 'Monoeixo',
            'placa' : 'Bifacial',
            'concesionaria' : 'CPFL Paulista',
            'status-operacao' : 'Em operação',
            'nome-terra' : {
                "portuguese" : "Direito de Superfície",
                "english" : "Land Rented for"
            }
        },
        {
            'nome' : 'Oeiras',
            'id' : 'oieiras',
            'imagem' : Oeiras,
            'posicao' : {'top' : 0, 'left' : 0},
            'local' : 'Oieras - PI',
            'potencia-instalada' : '5,00 MW',
            'potencia-pico' : '6,28 MWp',
            'energia-gerada' : '13.180 MWh/',
            'terra' : '30',
            'tracker' : 'Monoeixo',
            'placa' : 'Bifacial',
            'concesionaria' : 'Equatorial PI',
            'status-operacao' : 'Em operação',
            'nome-terra' : {
                "portuguese" : "Direito de Superfície",
                "english" : "Land Rented for"
            }
        },
        {
            'nome' : 'Mata Roma',
            'id' : 'mataroma',
            'imagem' : MataRoma,
            'posicao' : {'top' : 0, 'left' : 0},
            'local' : 'Mata Roma - MA',
            'potencia-instalada' : '4,00 MW',
            'potencia-pico' : '4,80 MWp',
            'energia-gerada' : '9.809 MWh/',
            'terra' : '35',
            'tracker' : 'Monoeixo',
            'placa' : 'Bifacial',
            'concesionaria' : 'Equatorial MA',
            'status-operacao' : 'Em operação',
            'nome-terra' : {
                "portuguese" : "Direito de Superfície",
                "english" : "Land Rented for"
            }
        },
        {
            'nome' : 'Cumbica',
            'id' : 'cumbica',
            'imagem' : Cumbica,
            'posicao' : {'top' : 0, 'left' : 0},
            'local' : 'Guarulhos - SP',
            'potencia-instalada' : '2,40 MW',
            'potencia-pico' : '3,52 MWp',
            'energia-gerada' : '5.036 MWh/',
            'terra' : '30',
            'tracker' : 'Monoeixo',
            'placa' : 'Bifacial',
            'concesionaria' : 'EDP SP',
            'status-operacao' : 'Em operação',
            'nome-terra' : {
                "portuguese" : "Locação de Telhado",
                "english" : "Roof Rented for"
            }
        },
        {
            'nome' : 'Santo Antônio',
            'id' : 'santoantonio',
            'imagem' : SantoAntonio,
            'posicao' : {'top' : 0, 'left' : 0},
            'local' : 'Adolfo - SP',
            'potencia-instalada' : '3,00 MW',
            'potencia-pico' : '4,28 MWp',
            'energia-gerada' : '8.685 MWh/',
            'terra' : '30',
            'tracker' : 'Monoeixo',
            'placa' : 'Bifacial',
            'concesionaria' : 'Energisa S/SE',
            'status-operacao' : 'Em operação',
            'nome-terra' : {
                "portuguese" : "Direito de Superfície",
                "english" : "Land Rented for"
            }
        },
        {
            'nome' : 'Buriti Grande',
            'id' : 'buritisgrande',
            'imagem' : BuritisGrande,
            'posicao' : {'top' : 0, 'left' : 0},
            'local' : 'Picos - PI',
            'potencia-instalada' : '2,50 MW',
            'potencia-pico' : '3,14 MWp',
            'energia-gerada' : '6.590 MWh/',
            'terra' : '30',
            'tracker' : 'Monoeixo',
            'placa' : 'Bifacial',
            'concesionaria' : 'Equatorial PI',
            'status-operacao' : 'Em operação',
            'nome-terra' : {
                "portuguese" : "Direito de Superfície",
                "english" : "Land Rented for"
            }
        },
        {
            'nome' : 'Balsas',
            'id' : 'balsas',
            'imagem' : Balsas,
            'posicao' : {'top' : 0, 'left' : 0},
            'local' : 'Redenção do Gurgeia - PI',
            'potencia-instalada' : '2,50 MW',
            'potencia-pico' : '3,14 MWp',
            'energia-gerada' : '6.495 MWh/',
            'terra' : '35',
            'tracker' : 'Monoeixo',
            'placa' : 'Bifacial',
            'concesionaria' : 'Equatorial PI',
            'status-operacao' : 'Em operação',
            'nome-terra' : {
                "portuguese" : "Direito de Superfície",
                "english" : "Land Rented for"
            }
        }
    ]

    const translation = {
        portuguese : {
            ano : 'ano',
            anos : 'anos',
            local : 'Local',
            potenciaInstalada : 'Potência Instalada',
            potenciaPico : 'Potência Pico',
            energiaGerada : 'Energia Gerada',
            trackerInfo : 'Monoeixo',
            placas : "Placas",
            concessionaria : "Concessionária"
        },
        english : {
            ano : 'year',
            anos : 'years',
            local : 'Location',
            potenciaInstalada : 'Installed Capacity',
            potenciaPico : 'Maximum Capacity',
            energiaGerada : 'Energy Produced',
            trackerInfo : 'Single Axle',
            placas : "Panels",
            concessionaria : "Electric Distribution Company"
        }
    }

    return (
            <div id='projects-gallery' className="projects-gallery wrap">
                {projects.map((project, index) => {
                    return (
                        <div id={project.id} class="box">
                            <div className="boxInner">
                                <img id={'img'+project.id} src={project.imagem} />
                                <div class="titleBox">{project.nome}</div>
                                <div class='project-info'>
                                    <div class='project-info-title'><span className="bold-span">UFV {project.nome}</span></div>
                                    <div class='project-info-item'><span className="bold-span">{translation[props.language].local}: </span>{project.local}</div>
                                    <div class='project-info-item'><span className="bold-span">{translation[props.language].potenciaInstalada}: </span>{project['potencia-instalada']}</div>
                                    <div class='project-info-item'><span className="bold-span">{translation[props.language].potenciaPico}: </span>{project['potencia-pico']}</div>
                                    <div class='project-info-item'><span className="bold-span">{translation[props.language].energiaGerada}: </span>{project['energia-gerada'] + translation[props.language].ano}</div>
                                    <div class='project-info-item'><span className="bold-span">{project['nome-terra'][props.language]}: </span>{project['terra']} {translation[props.language].anos}</div>
                                    <div class='project-info-item'><span className="bold-span">Tracker: </span>{translation[props.language].trackerInfo}</div>
                                    <div class='project-info-item'><span className="bold-span">{translation[props.language].placas}: </span>{project['placa']}</div>
                                    <div class='project-info-item'><span className="bold-span">{translation[props.language].concessionaria}: </span>{project['concesionaria']}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
    );
}
 
export default ProjectsGallery;