import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './About.css';
import imgball1 from './../../images/blintor.svg';
import imgball2 from './../../images/nexenergy.svg';
import imgball3 from './../../images/imgball3.jpeg';
import { motion } from 'framer-motion';
 
function About(props)
{
    const handleClickProjects = (e) => {
        e.preventDefault()
        const target = '#projetos'
        const location = document.querySelector(target).offsetTop
    
        window.scrollTo({
          left: 0,
          top: location - document.getElementById('navbar').offsetHeight + 0.5,
        })
    }

    const translation = {
        "portuguese" : {
            titleCaption : "Sobre nós",
            text : <>
                Nosso planeta nos deu o sol e a água como fontes de energia vital à vida, que alimentam nossos sonhos, geram riqueza e desenvolvimento. Nada mais justo do que devolvê-lo como nos entregou, limpa. 
                Assim nasceu a Ângulo45, uma empresa feita de pessoas que acreditam que podem usar os preciosos recursos naturais do planeta, para se extrair riqueza sem agredir, preservando o que temos de mais valioso, nosso futuro. 
                <br/><br/>
                O Brasil é um País que tem abundância de sol, água e vento e uma necessidade tremenda de continuar dando conta de seu crescimento e desenvolvimento populacional. 
                <br/><br/>
                Acreditamos que gerar energia via Geração Distribuída (GD) ou descentralizada é o futuro democrático para mantermos nossa matriz energética limpa e acessível. Criamos assim uma concorrência com o modelo atual, onde o consumidor pouco sabe a respeito da fonte energética consumida, sem o poder de escolher pelo serviço prestado e seu custo. 
                <br/><br/>
                A GD vai além de criar um modelo novo de distribuição e geração de energia. Desenvolveu em tão pouco tempo um novo ecossistema de negócios que emprega milhares de pessoas mundo afora.
            </>
        },
        "english" : {
            titleCaption : "About Us",
            text : <>
                Our planet has given us the sun and water as sources of vital energy for life, which sustains our dreams, and generates wealth and development. Nothing more just than returning this energy as it was delivered to us, clean.
                This is how Ângulo45 was born. A company made up of people who believe they can use the planet's precious natural resources to extract wealth without harm, preserving what is most valuable to us, our future.
                <br/><br/>
                Brazil is a country that has an abundance of sunlight, water, and wind and a tremendous need to keep up with its population growth and development.
                <br/><br/>
                We believe that generating energy via Distributed Generation (DG) or decentralized is the democratic future to keep our energy matrix clean and accessible. Thus, we created a competitor to the current model, where the consumer knows little about the energy source consumed and lacks the power to choose the service provided and its cost.
                <br/><br/>
                DG goes beyond creating a new energy distribution and generation model. It has developed in such a short time a new business ecosystem that employs thousands of people around the world.
            </>
        }
    }

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
    }
      
    window.addEventListener("scroll", reveal);      

    return (
        <div id="sobrenos" className='about-section'>
            <div className="container about-container">
                <div className="row">
                    <div id="img-about-container" className="col-lg-6 col-md-12 img-about-container">
                        <table className="table-imgs">
                            <tr>
                                <a href='http://www.blintor.com.br/' target='_blank'>
                                    <motion.img
                                        className='img-ball img-ball-1 reveal reveal-1'
                                        src={imgball1}
                                        whileTap={{scale: 0.9 }}
                                        whileHover={{scale: 1.1}}
                                        drag={true}
                                        dragConstraints={{left: 0, right: 0, top: 0, bottom: 0}}
                                    />
                                </a>
                                <a href='https://www.nexenergy.com.br/' target='_blank'>
                                    <motion.img
                                        className='img-ball img-ball-2 reveal reveal-2'
                                        src={imgball2}
                                        whileTap={{scale: 0.9 }}
                                        whileHover={{scale: 1.1}}
                                        drag={true}
                                        dragConstraints={{left: 0, right: 0, top: 0, bottom: 0}}
                                    />
                                </a>
                            </tr>
                            <tr>
                                <motion.img
                                    className='img-ball img-ball-3 reveal reveal-3'
                                    src={imgball3}
                                    whileTap={{scale: 0.9 }}
                                    whileHover={{scale: 1.1}}
                                    drag={true}
                                    dragConstraints={{left: 0, right: 0, top: 0, bottom: 0}}
                                    onClick={handleClickProjects}
                                />
                            </tr>
                        </table>
                    </div>
                    <div className="reveal reveal-4 col-lg-6 col-md-12 about-section about-content-container">
                        <div className='subtitle-container'>
                            <div className='subtitle-strip'></div>
                            <p className='about-subtitle-text'>{translation[props.language].titleCaption}</p>
                        </div>
                        <h1 className="about-title">Ângulo<span className="red-text">45</span></h1>
                        <p className="about-paragraph">
                            {translation[props.language].text}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default About;