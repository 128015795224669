import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import './Footer.css';
 
function Footer(props)
{

    const translation = {
        "portuguese" : "sala",
        "english" : "room"
    }

    return (
        <div id='footer' className='footer'>
            <p className='footer-text'>Av. Anita Garibaldi, 850, {translation[props.language]} 510c | Cabral | Curitiba-PR<br/>+55 41 3328 4207 | contato@blintor.com.br</p>
        </div>
    );
}
 
export default Footer;