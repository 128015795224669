import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './Projects.css';
import BrazilMap from './../../images/brazilmap.png';

function Projects(props)
{
    const projects = [
        {
            'nome' : 'Cafelândia',
            'id' : 'cafelandia',
            'posicao' : {'top' : 285, 'left' : 225},
        },
        {
            'nome' : 'Pongaí',
            'id' : 'pongai',
            'posicao' : {'top' : 283, 'left' : 240},
        },
        {
            'nome' : 'Oeiras',
            'id' : 'oieiras',
            'posicao' : {'top' : 125, 'left' : 328},
        },
        {
            'nome' : 'Mata Roma',
            'id' : 'mataroma',
            'posicao' : {'top' : 108, 'left' : 300},
        },
        {
            'nome' : 'Cumbica',
            'id' : 'cumbica',
            'posicao' : {'top' : 295, 'left' : 260},
        },
        {
            'nome' : 'Buritis Grande',
            'id' : 'buritisgrande',
            'posicao' : {'top' : 130, 'left' : 350},
        },
        {
            'nome' : 'Balsas',
            'id' : 'balsas',
            'posicao' : {'top' : 150, 'left' : 320},
        },
        {
            'nome' : 'Santo Antônio',
            'id' : 'santoantonio',
            'posicao' : {'top' : 272, 'left' : 224},
        }
    ]

    const translation = {
        "portuguese" : {
            titleCaption : "Usinas Solares",
            title : "Projetos",
            text : <>
                Nosso Brasil, com seu imenso território e sol em abundância, nos permitiu desenvolver e construir projetos de Usinas Solares Fotovoltaicas em regime de Geração Distribuída nos Estados de São Paulo, Piauí e Maranhão.
                <br/><br/>
                De áreas rurais em regiões remotas e pouco desenvolvidas a telhados em armazéns logísticos Triple A em grandes cidades, a Ângulo45 tem o compromisso de desenvolver e implementar projetos de geração solar com a mais alta tecnologia e nível de excelência. Com esta visão, contribuímos para nosso desenvolvimento regional, atendendo ao mercado de pequenos consumidores com energia limpa e barata. 
                <br/><br/>
                Ao mesmo tempo, oferecemos a oportunidade de maximizar a rentabilidade do capital de nossos investidores, com uma melhor matriz de diversificação de riscos.
            </>
        },
        "english" : {
            titleCaption : "Solar Power Plants",
            title : "Projects",
            text : <>
                Brazil, with its immense territory and abundant sunlight, has allowed us to develop and build projects for Solar Photovoltaic Plants in a Distributed Generation regime in the States of São Paulo, Piauí, and Maranhão.
                <br/><br/>
                From rural areas in remote and underdeveloped regions to roofs in Triple-A logistics warehouses in large cities, Ângulo45 is committed to developing and implementing solar generation projects with the highest technology and a level of excellence. With this vision, we contribute to our regional development, serving the market of small consumers with clean and cheap energy.
                <br/><br/>
                At the same time, we offer the opportunity to maximize the return on capital for our investors, with a better risk diversification matrix.
            </>
        }
    }

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    function addFlash(id) {
        const target = id
        const location = document.getElementById(target).offsetTop
    
        window.scrollTo({
          left: 0,
          top: location - document.getElementById('navbar').offsetHeight + 0.5,
        })
        document.getElementById(id).classList.add('flash-class');
        document.getElementById('img'+id).classList.add('red-border-image');
        for (let i = 0; i < projects.length; i++) {
            if (projects[i].id !== id) {
                document.getElementById('img'+projects[i].id).classList.remove('red-border-image');
            }
        }

        setTimeout(function(){ 
            document.getElementById(id).classList.remove('flash-class');
        }, 3000);  
    }

    return (
        <div id="projetos" className='projects-container'>
            <div className='projects-title'>
                <div className='subtitle-container'>
                    <div className='subtitle-strip'></div>
                    <p className='about-subtitle-text'>{translation[props.language].titleCaption}</p>
                </div>
                <h1 className="projects-heading-text about-title">{translation[props.language].title}</h1>
            </div>
            <div className='projects-content container'>
                <div className="row">
                    <div className="projects-text-container col-lg-6 col-md-12 col-sm-12">
                        <p className="projects-text">
                            {translation[props.language].text}
                        </p>
                    </div>
                    <div className="brazil-img-container col-lg-6 col-md-12 col-sm-12">
                        <div className="brazil-img" style={{"background": 'url("'+ BrazilMap +'")', "backgroundPosition": "center",
                        "backgroundRepeat" :"noRepeat", "backgroundSize": "cover"}}>
                            {projects.map((project, index) => {
                                return (
                                    <div onClick={() => addFlash(project.id)} style={{'position': 'absolute', 'top': project.posicao.top, 'left': project.posicao.left}} className="project-button" key={index}></div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Projects;