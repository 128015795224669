import './App.css';
import React, { useState } from 'react';
import Home from './Pages/Home';
import Investidores from './Pages/Investidores';
import Contato from './Pages/Contato';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/footer/Footer';
import portuguese from './images/portuguese.png';
import english from './images/uk.png';


function App() {

  const [language, setLanguage] = useState('portuguese');

  return (
    <Router>
      <div className="App">
        <div className="language-container">
          <img onClick={() => setLanguage('portuguese')} src={portuguese} alt="português" className="flag" />
          <img onClick={() => setLanguage('english')}  src={english} alt="english" className="flag" />
        </div>
        <Routes>
          <Route exact path="/" element={<Home language={language}/>} />
          <Route path="/investidores" element={<Investidores language={language}/>} />
          <Route path="/contato" element={<Contato language={language}/>} />
        </Routes>
        <Footer language={language} />
      </div>
    </Router>
  );
}

export default App;
