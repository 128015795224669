import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './../../images/angulo45logocompleto.png';


function Navbar(props)
{
    const [menu, setMenu] = useState(
        {
            "portuguese" : [
                {
                    "title" : "Sobre nós",
                    "link" : "#sobrenos"
                },
                {
                    "title" : "Projetos",
                    "link" : "#projetos"
                },
                {
                    "title" : "Investidores",
                    "link" : "/investidores"
                },
                {
                    "title" : "Contato",
                    "link" : "/contato"
                }
            ],
            "english" : [
                {
                    "title" : "About Us",
                    "link" : "#sobrenos"
                },
                {
                    "title" : "Projects",
                    "link" : "#projetos"
                },
                {
                    "title" : "Investors",
                    "link" : "/investidores"
                },
                {
                    "title" : "Contact",
                    "link" : "/contato"
                }
            ]
        }
    );

    const handleClick = (e) => {
        e.preventDefault()
        const target = e.target.getAttribute('href')
        const location = document.querySelector(target).offsetTop
    
        window.scrollTo({
          left: 0,
          top: location - document.getElementById('navbar').offsetHeight + 0.5,
        })
    }

      const handleClickHome = (e) => {
        e.preventDefault()

        window.scrollTo({
          left: 0,
          top: 0,
        })
      }

    return (
        <nav id="navbar" class="navbar sticky-top navbar-expand-lg">
            <div class="container-fluid">
                {
                    props.atHome ? 
                        <Link class="navbar-brand" className="header-title" to='/' onClick={handleClickHome}>
                            <img src={logo} className="d-inline-block align-text-top logo-img"/>
                        </Link>
                    :
                    <Link class="navbar-brand" className="header-title" to='/'>
                        <img src={logo} className="d-inline-block align-text-top logo-img"/>
                    </Link>
                }
                
                <button class="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="toggler-icon top-bar"></span>
                    <span class="toggler-icon middle-bar"></span>
                    <span class="toggler-icon bottom-bar"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <div class="navbar-nav">
                        {menu[props.language].map((item, index) => (
                            props.atHome ?
                            item.link[0] == '#' ?
                                <a class="nav-link current" aria-current="page" href={item.link} onClick={handleClick}>{item.title}</a>
                            : 
                                <Link to={item.link} className="nav-link current">{item.title}</Link>
                         :
                        item.link[0] == '#' ?
                            <Link to='/' state={{ section: item.link }} className="nav-link current">{item.title}</Link>
                            : 
                                <Link to={item.link} className="nav-link current">{item.title}</Link>

                        ))
                        }
                    </div>
                </div>
            </div>
        </nav>
    );
}
 
export default Navbar;