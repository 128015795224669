import React, { useState, useEffect } from 'react';
import Header from './../components/header/Header';
import Navbar from './../components/navbar/Navbar';
import About from './../components/about/About';
import Projects from './../components/projects/Projects';
import Time from '../components/time/Time';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import ProjectsGallery from '../components/projects/ProjectsGalllery';

function Home(props) {
    const location = useLocation()

    useEffect(() => {
        var state = location.state;
        if (state != null) {
            const target = state.section;
            const location = document.querySelector(target).offsetTop
        
            window.scrollTo({
              left: 0,
              top: location - document.getElementById('navbar').offsetHeight + 0.5,
            })
        }
    }, [])
    
    return (
        <div className="HomePage">
            <Navbar atHome={true} language={props.language} />
            <Header language={props.language} />
            <About language={props.language} />
            <Time language={props.language} />
            <Projects language={props.language} />
            <ProjectsGallery language={props.language} />
        </div>
  );
}

export default Home;