import React, { useEffect, useState } from 'react';
import Navbar from './../components/navbar/Navbar';
import './Contato.css';


function Contato(props) {

    const [nome, setNome] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [email, setEmail] = useState('');

    const translation = {
        "portuguese" : {
            titleCaption : "Contato",
            title : "Fale Conosco",
            nome : "NOME",
            mensagem : "MENSAGEM",
            enviar : "ENVIAR",
        },
        "english" : {
            titleCaption : "Contact",
            title : "Let's Talk",
            nome : "NAME",
            mensagem : "MESSAGE",
            enviar : "SEND",
        }
    }
    
    useEffect(() => {
        document.getElementsByClassName('contacts-page')[0].style.minHeight = window.innerHeight - document.getElementById('footer').offsetHeight - 40 + 'px';
    } , []);

    function handleSubmit() {

    }

    return (
        <div className="contacts-page">
            <Navbar atHome={false} language={props.language} />
            <div className='contacts-content'>
                <div className='contacts-title'>
                    <div className='subtitle-container'>
                        <div className='subtitle-strip'></div>
                        <p className='about-subtitle-text'>{translation[props.language].titleCaption}</p>
                    </div>
                    <h1 className="about-title">{translation[props.language].title}</h1>
                </div>
                <div className='form-container col-lg-9 col-md-9 col-sm-12'>
                    <form className='contact-form' action="https://formsubmit.co/1749103ce38ad8bbc434c85a000085bc" method="POST" onSubmit={handleSubmit}>
                        <textarea placeholder={translation[props.language].nome} name='nome' required value={nome} onChange={e => {setNome(e.target.value)}} />
                        <textarea className='message-field' placeholder={translation[props.language].mensagem} name='mensagem' value={mensagem} onChange={e => {setMensagem(e.target.value)}} />
                        <textarea placeholder='EMAIL' name='email' type='email' value={email} onChange={e => {setEmail(e.target.value)}} />
                        <input type="hidden" name="_captcha" value="false" />
                        <input type="hidden" name="_next" value="https://angulo45.com.br/" />
                        <button className='submit-button' type="submit">{translation[props.language].enviar}</button>
                        <div className='contact-information'>
                            <div className='information-container'><i className="fa-regular fa-map contact-icon"></i><a className='contact-link' href='https://goo.gl/maps/AM18T1w3TjWFNzCH9' target='_blank'>Av. Anita Garibaldi, 850, sala 510c | Cabral | Curitiba-PR</a></div>
                            <div className='information-container'><i className="fa-solid fa-phone contact-icon"></i>+55 41 3328 4207</div>
                            <div className='information-container'><i className="fa-solid fa-envelope contact-icon"></i>contato@blintor.com.br</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
  );
}

export default Contato;