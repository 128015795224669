import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import './Time.css';
import imgAlan from './../../images/alan.jpg';
import imgJose from './../../images/joseluis.jpg';
import imgAlexandre from './../../images/alexandre.jpeg';
 
function Time(props)
{
    const people = 
    {
        "portuguese" : [
            {
                name: 'Alan Fuchs',
                description: '25 anos de experiência em logística, finanças e infra estrutura. CFO e CEO de empresas. Hoje é CEO da Blintor Capital. Alan é graduado em ciências econômicas pela Universidade Federal do Rio de Janeiro. Mestre em administração financeira pelo COPPEAD - UFRJ',
                title: 'Sócio Fundador',
                image: imgAlan,
            },
            {
                name: 'José Luis Demeterco Neto',
                description: '29 anos de experiência em varejo, logística, e infra estrutura. Fundador e CEO de diversas empresas. Em 2015 formou-se no OPM em Harvard e hoje ocupa a posição de Chairman da Blintor Capital. José Luis é graduado em Marketing pela Southern Illinois University at Carbondale.            ',
                title: 'Sócio Fundador',
                image: imgJose,
            },
            {
                name: 'Alexandre Bueno',
                description: 'Universidade Católica Dom Bosco, Graduado de Direito 1998; University of Pittsburgh, MBA 2014. Ex VP de Operações da Brado Logística. 10 anos como executivo da ALL (América Latina Logística S.A). Ex COO Estre Ambiental.',
                title: 'CEO',
                image: imgAlexandre,
            }
        ],

        "english" : [
            {
                name: 'Alan Fuchs',
                description: "25 years of experience in logistics, finance, and infrastructure. CFO and CEO of different companies and currently CEO of Blintor Capital. Alan graduated in economic sciences from the Federal University of Rio de Janeiro and has a Master's degree in financial business from COPPEAD - UFRJ.",
                title: 'Founding Partner',
                image: imgAlan,
            },
            {
                name: 'José Luis Demeterco Neto',
                description: '29 years of experience in retail, logistics, and infrastructure. Founder and CEO of several companies. In 2015 graduated in OPM from Harvard and today occupies the position of Chairman at Blintor Capital. José Luis graduated in Marketing from the Southern Illinois University at Carbondale.',
                title: 'Founding Partner',
                image: imgJose,
            },
            {
                name: 'Alexandre Bueno',
                description: 'Universidade Católica Dom Bosco, Law Degree 1998; University of Pittsburgh, MBA 2014. Former VP of Operations of Brado Logística. Executive leading positions during more than 10 years at ALL (América Latina Logística S.A). Former COO of Estre Ambiental.',
                title: 'CEO',
                image: imgAlexandre,
            }
        ],
    }

    return (
        <div className='time'>
            <div className='container parceiros-container'>
                <div className='row'>
                    <div className='people'>
                        {people[props.language].map((person, index) => {
                            return (
                                <div className='person col-lg-4 col-md-12' key={index}>
                                    <div className='top-person'>
                                        <div>
                                            <img className='person-image' src={person.image} alt={person.name} />
                                        </div>
                                        <div className='person-info'>
                                            <div className='person-name'>
                                                {person.name}
                                            </div>
                                            <div className='person-title'>
                                                {person.title}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='person-description'>
                                        {person.description}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
 
export default Time;