import React, { useState, useEffect } from 'react';
import Navbar from './../components/navbar/Navbar';
import './Investidores.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


function Investidores(props) {
    const balancoAuditado = [
        {
            "english" : "Audited Balance Sheet Ângulo45 2020",
            "portuguese" : "Balanço Auditado Ângulo45 2020",
            "file" : "https://angulo45.com.br/DFS-ANGULO45-31122020V4-assinado.pdf"
        },
        {
            "english" : "Audited Balance Sheet Ângulo45 2021",
            "portuguese" : "Balanço Auditado Ângulo45 2021",
            "file" : "https://angulo45.com.br/DFS-ANGULO45-31122021V3-ajustado-assinado.pdf"
        }
    ]

    const fatosRelevantes = [
        
    ]

    const [translation, setTranslation] = useState({
        portuguese : {
            titleCaption : "Investidores",
            title : "Central de Downloads",
            balanco : "Balanço Auditado",
            fatos : "Fatos Relevantes",
        },
        english : {
            titleCaption : "Investors",
            title : "Downloads Panel",
            balanco : "Audited Balance Sheet",
            fatos : "Relevant Facts",
        }
    });

    useEffect(() => {
        document.getElementsByClassName('investors-page')[0].style.minHeight = window.innerHeight - document.getElementById('footer').offsetHeight - 40 + 'px';
    }, []);

    // document.getElementsByClassName('investidores-content')[0].style.height = window.innerHeight - document.getElementById('navbar').offsetHeight - 170 + 'px';

    return (
        <div className="investors-page">
            <Navbar atHome={false} language={props.language} />
            <div className='investors-content'>
                <div className='investors-title'>
                    <div className='subtitle-container'>
                        <div className='subtitle-strip'></div>
                        <p className='about-subtitle-text'>{translation[props.language].titleCaption}</p>
                    </div>
                    <h1 className="about-title">{translation[props.language].title}</h1>
                </div>
                <div className='container container-downloads'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 coluna-download'>
                            <h1 className='coluna-title'>{translation[props.language].balanco}</h1>
                            <ul className='files-download'>
                                {balancoAuditado.map((item, index) => {
                                    return (
                                        <li className='file-item' key={index}>
                                            <a className='file-link' href={item.file} download rel="noopener noreferrer" target="_blank">
                                                {item[props.language]}
                                                <i className="download-icon fa-solid fa-download"></i>
                                            </a>
                                        </li>
                                    )
                                }
                                )}
                            </ul>
                        </div>
                        <div className='col-lg-6 col-md-12 coluna-download'>
                            <h1 className='coluna-title'>{translation[props.language].fatos}</h1>
                            <ul className='files-download'>
                                {fatosRelevantes.map((item, index) => {
                                        return (
                                            <li className='file-item' key={index}>
                                                <a className='file-link' href={item.file} rel="noopener noreferrer" target="_blank">
                                                    {item[props.language]}
                                                    <i className="download-icon fa-solid fa-download"></i>
                                                </a>
                                            </li>
                                        )
                                    }
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Investidores;