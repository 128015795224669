import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './Header.css';
import headerImg from './../../images/header2.jpg';
import headerVideo from './../../videos/cafelandiavideo.mp4';
 
function Header(props)
{
    const handleClick = (e) => {
        e.preventDefault()
        const target = e.target.getAttribute('href')
        const location = document.querySelector(target).offsetTop
    
        window.scrollTo({
            left: 0,
            top: location - document.getElementById('navbar').offsetHeight + 0.5,
        })
    }

    const [translation, setTranslation] = useState(
        {
            "portuguese" : {
                heading : <>Energia Solar em Geração Distribuída<br/>para seu Negócio</>,
                buttonText : <>Sobre Nós</>
            },
            "english" : {
                heading : <>Solar Energy in Distributed Generation<br/>for your Business</>,
                buttonText : <>About Us</>
            }
        }
    );

    useEffect(() => {
        document.getElementsByClassName('background-header')[0].style.height = window.innerHeight - document.getElementById('navbar').offsetHeight + 0.5 + 'px';
        document.getElementById('background-video').style.height = window.innerHeight - document.getElementById('navbar').offsetHeight + 0.5 + 'px';
        // document.getElementsByClassName('header-slider')[0].style.height = window.innerHeight - document.getElementById('navbar').offsetHeight + 0.5 + 'px';
        document.getElementById("background-video").play();
        console.log("playing video")
    } , []);
    
    return (
        <div id="header" className='background-header video-wrapper'>
            {/* <div className="header-slider video-wraper" style={{"background": 'url("'+ headerImg +'")', "backgroundPosition": "center",
                "backgroundRepeat" :"noRepeat", "backgroundSize": "cover"}}>
            </div> */}
            
            <video className='video' id="background-video" muted autoplay loop poster={headerImg}>
                <source src={headerVideo} type="video/mp4"/>
            </video>

            <div className="header-content">
                <h1 className="header-text">{translation[props.language].heading}</h1>
                <h3 className="header-caption"><a href="#sobrenos" className="sobrenos-link" onClick={handleClick}>{translation[props.language].buttonText}</a></h3>
            </div>
            <div className="red-stripe"></div>
        </div>
    );
}
 
export default Header;